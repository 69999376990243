import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Header from '../components/Header';
import PropTypes from 'prop-types';

const PageWithTOC = function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { html } = markdownRemark;
  return (
    <Layout>
      <SEO title='Frequently asked questions about plug trays' />
      <Header />
      <div className='longform' dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
};
export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
      }
    }
  }
`;

PageWithTOC.displayName = 'PageWithTOCTemplate';

PageWithTOC.defaultProps = {
  data: undefined,
};

PageWithTOC.propTypes = {
  data: PropTypes.object,
};

export default PageWithTOC;
